<template>
  <v-card class="MyCardL" style="background: white; opacity: 1">
    <div>
      <v-card-text style="text-align: center">
        <div class="MyCardLX noselect">
          <v-expand-transition>
            <div class="Img1X">
              <img
                src="../../assets/logoticket2pass.png"
                draggable="false"
                width="200"
                height="auto"
                class="CImg1"
              />
            </div>
          </v-expand-transition>
          <div>
            <img
              src="../../assets/ticket2pass.png"
              draggable="false"
              class="CImg2"
              width="350"
              height="auto"
            />
          </div>
          <div class="Version">versión: {{ versionX }}</div>
        </div>
        <div class="MyZoom">
          <v-expand-transition>
            <h5 v-if="recoPass" style="text-align: left">
              Escribe el correo registrado para recuperar contraseña.
            </h5>
          </v-expand-transition>
          <v-form style="padding: 0; margin: 0" ref="formLoginAndRecovery">
            <v-text-field
              :append-icon="recoPass ? 'email' : 'person'"
              name="Usuario"
              :label="recoPass ? 'Correo electronico' : 'Usuario'"
              type="text"
              outlined
              v-model="userEmail"
              :error="error"
              :rules="
                recoPass ? [rules.required, rules.email] : [rules.required]
              "
              v-on:keyup.13="login"
            />

            <v-expand-transition>
              <div v-if="!recoPass">
                <v-text-field
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  label="Password"
                  autocomplete="on"
                  outlined
                  id="password"
                  :rules="[rules.required]"
                  v-model="password"
                  :error="error"
                  @click:append="hidePassword = !hidePassword"
                  v-on:keyup.13="login"
                  style="margin: 0; padding: 0"
                />
              </div>
            </v-expand-transition>
          </v-form>

          <v-row justify="center" style="gap: 30px">
            <v-btn
              v-if="!recoPass"
              :color="'#dd8159'"
              min-width="148"
              @click="changeView"
              class="my_font"
            >
              Crear cuenta
            </v-btn>
            <v-btn
              v-else
              :color="'#dd8159'"
              min-width="124"
              @click="recoPass = !recoPass"
              class="my_font"
            >
              Regresar
            </v-btn>

            <v-btn
              :color="config[pagina].btn1"
              @click="login"
              :loading="loading"
              :disabled="loading"
              class="my_font"
              v-if="!recoPass"
              min-width="148"
            >
              Entrar
            </v-btn>
            <v-btn
              :color="config[pagina].btn1"
              @click="recoveryPassword"
              :loading="loading"
              :disabled="loading"
              v-else
              min-width="124"
              class="my_font"
            >
              Recuperar
            </v-btn>
          </v-row>
          <v-row
            justify="center"
            style="padding: 20px 20px 0px 20px"
            v-if="!recoPass"
          >
            <a href="#" @click="recoPass = !recoPass">
              <h5 class="mb-5 mt-n2" style="color:#f07f3a;">
                Recupera tu contraseña
              </h5>
            </a>
          </v-row>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import https from "https";

export default {
  name: "LoginCard",
  data() {
    return {
      error: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      },
      userEmail: "",
      password: "",
      hidePassword: true,
      pagina: 0,
      config: [
        {
          logo: "../assets/logo55.png",
          titulo: "eBid",
          color0: "white",
          color1: "#8bc34a",
          text1: "#259b24",
          btn1: "primary",
        },
      ],
      loading: false,
      recoPass: false,
      versionX: "",
    };
  },
  created() {
    this.versionX = this.$root.versionX;
  },
  watch: {
    recoPass: function() {
      this.userEmail = "";
      this.password = "";
      this.$refs.formLoginAndRecovery.resetValidation();
    },
  },
  methods: {
    login: function() {
      this.loading = true;
      if (this.$refs.formLoginAndRecovery.validate()) {
        let agentOptions = {
          rejectUnauthorized: false,
          ca: this.$root.cer,
        };
        const requestOption = {
          method: "POST",
          httpsAgent: new https.Agent(agentOptions),
          url: this.$root.myserver + this.$root.myport + "/Login/",
          json: true,
          data: {
            useX: this.userEmail,
            pasX: this.password,
          },
        };
        axios(requestOption)
          .then((response) => {
            var info = response.data;
            this.loading = false;
            if (info.success == "TRUE") {
              this.$root.token = response.data;
              this.$cookies.set("user", response.data);

              //var time = Date.now()
              //this.$router.push('Dashboard?u='+time)
              if (response.data.rol == 4) {
                this.$router.push({ name: "Catalogo" });
              } else {
                this.$router.push({ name: "Dashboard" });
              }
            } else {
              this.$swal({
                icon: "error",
                title: "Acceso denegado",
                text: info.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          })
          .finally(() => {});
      }
    },
    changeView: function() {
      this.$parent.view = !this.$parent.view;
    },
    recoveryPassword: function() {
      if (this.$refs.formLoginAndRecovery.validate()) {
        let agentOptions = {
          rejectUnauthorized: false,
          ca: this.$root.cer,
        };
        const requestOption = {
          method: "POST",
          httpsAgent: new https.Agent(agentOptions),
          url:
            this.$root.myserver + this.$root.myport + "/Login/recoveryPassword",
          json: true,
          data: {
            email: this.userEmail,
          },
        };
        axios(requestOption).then((response) => {
          if (response.data.success) {
            this.$root.swalAlert("success", "Success", response.data.message);
            this.recoPass = false;
          } else {
            this.$root.swalAlert("error", "Error", response.data.message);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.Version{
      position: absolute;
    z-index: 0;
    left: calc(100% - 94px);
    margin-top: -24px;
    padding: 0px 4px 0px 4px;
    border-radius: 16px;
    font-weight: bold;
    font-size: 10px;
}
.my_font {
  margin-top: 5px;
  margin-bottom: 10px;
  color: white !important;
}
.CImg1 {
  filter: drop-shadow(0 0 0.55rem #4e54c8);
}
.CImg2 {
  padding: 10px 20px 20px 20px;
  filter: drop-shadow(0 0 0.55rem #4e54c8);
}
.MyZoom {
  zoom: 80%;
}
.MyCardL {
  max-width: 350px !important;
  -moz-transform:  scale(1,0.9);
  transform:  scale(1,0.9);
}

@media only screen and (max-width: 700px) {
  .MyZoom {
    zoom: 90%;
  }
  .MyCardL {
    -moz-transform:  scale(1,1);
    transform:  scale(1,1);
  }
}
@media (max-height: 530px) and (orientation: landscape) {
  .Img1X {
    display: none;
  }
}
</style>
