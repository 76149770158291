<template>
  <v-card class="MyCardL2" style="background: white; opacity: 1">
    <v-card-title dark> Registro de mayoristas </v-card-title>
    <div>
      <v-card-text style="text-align: center">
        <div class="MyZoom">
          <v-form ref="signInForm">
            <v-row justify-sm="center">
              <v-col cols="12" sm="6" class="withoutpadding">
                <v-text-field
                  append-icon="email"
                  name="Correo Personal"
                  label="Correo Personal"
                  type="email"
                  outlined
                  v-model="formCoor.email"
                  :error="error"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col cols="12" sm="6" class="withoutpadding">
                <v-text-field
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="Contrasena"
                  label="Contraseña"
                  outlined
                  @click:append="hidePassword = !hidePassword"
                  v-model="formCoor.clave"
                  :error="error"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" class="withoutpadding">
                <v-text-field
                  append-icon="person"
                  name="Nombre"
                  label="Nombre"
                  type="text"
                  outlined
                  v-model="formCoor.name"
                  :error="error"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="6" class="withoutpadding">
                <v-text-field
                  append-icon="person"
                  name="ApellidoPaterno"
                  label="Apellido Paterno"
                  type="text"
                  outlined
                  v-model="formCoor.name2"
                  :error="error"
                  :rules="[]"
                />
              </v-col>
              <v-col cols="12" sm="6" class="withoutpadding">
                <v-text-field
                  append-icon="person"
                  name="ApellidoMaterno"
                  label="Apellido Materno"
                  type="text"
                  outlined
                  v-model="formCoor.name3"
                  :error="error"
                  :rules="[]"
                />
              </v-col>
              <h5 class="subtitle">Datos de la Empresa</h5>
              <v-col cols="12" class="withoutpadding">
                <v-text-field
                  class="rfcInput"
                  @keyup="uppercase"
                  append-icon="badge"
                  name="RFC"
                  label="RFC"
                  type="text"
                  outlined
                  v-model="formCoor.companyRFC"
                  :error="error"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" class="withoutpadding">
                <v-text-field
                  append-icon="business"
                  name="CompanyName"
                  label="Nombre"
                  type="text"
                  outlined
                  v-model="formCoor.companyName"
                  :error="error"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="12" class="withoutpadding">
                <v-text-field
                  append-icon="location_on"
                  name="Direccion"
                  label="Dirección"
                  type="text"
                  outlined
                  v-model="formCoor.companyAddress"
                  :rules="[]"
                />
              </v-col>
              <v-col cols="12" sm="6" class="withoutpadding">
                <v-text-field
                  append-icon="phone"
                  name="Telefono"
                  label="Telefono"
                  type="text"
                  outlined
                  v-model="formCoor.companyPhone"
                  :rules="[]"
                />
              </v-col>
              <v-col cols="12" sm="6" class="withoutpadding"> </v-col>
            </v-row>
          </v-form>
          <v-row justify="center" class="sing-buttons">
            <v-btn
              min-width="211"
              color="#dd8159"
              @click="changeView"
              class="my_font"
            >
              Ya tengo una cuenta
            </v-btn>

            <v-btn
              :color="'primary'"
              @click="viewPrivacyPolicy"
              :loading="loading"
              min-width="211"
              class="my_font"
            >
              Registrarse
            </v-btn>
          </v-row>
        </div>
      </v-card-text>

      <v-dialog v-model="dialog" scrollable persistent max-width="750px">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <v-spacer></v-spacer>
            <v-btn icon dark color="black" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text
            id="privacyPolicy"
            class="py-3 terminos"
            @scroll="onScroll"
          >
            <v-row class="mb-5" style="padding: 10px 20px">
              <img
                src="../../assets/logo-yucatan-header.jpg"
                draggable="false"
                width="auto"
                height="40"
              />
              <v-spacer></v-spacer>
              <img
                src="../../assets/logo-aafy-header.jpg"
                draggable="false"
                width="auto"
                height="40"
              />
            </v-row>
            <h6 class="text-center my-3 mb-4">TÉRMINOS Y CONDICIONES</h6>
            <p class="mb-5">
              Al visitar este sitio y/o realizar compras en el mismo aceptas los
              siguientes términos y condiciones siguientes:
            </p>
            <p class="mb-5">Procedimiento de Compra de Boletos</p>
            <ul class="list-number">
              <li>
                Deberá generar usuario y contraseña por única vez. El resguardo
                del usuario y contraseña son responsabilidad exclusivamente de
                quien lo genera.
              </li>
              <li>
                Para realizar compras entrará con el usuario y contraseña
                generados.
              </li>
              <li>
                Colocará en su carrito las cantidades de boletos del parador que
                desee adquirir.
              </li>
              <li>
                Posteriormente en el carrito generará la orden de pago. En la
                misma se indica la línea de referencia que deberá incluir al
                momento de realizar su transferencia, así como la vigencia de la
                misma.
              </li>
              <li>
                Realizará la transferencia de acuerdo a la guía que se incluye
                en la orden de pago.
              </li>
              <li>
                La validación de las líneas de transferencia se realizará en un
                lapso máximo de una hora por el personal de la Agencia de
                Administración Fiscal de Yucatán en horario de oficina de lunes
                a viernes de 8:00 a 15:00 hrs. Las transferencias que se reciban
                posterior al horario indicado se validarán en el horario hábil
                siguiente.
              </li>
              <li>
                Posterior a la validación quedará disponible en el sitio web la
                cantidad de boletos adquiridos, mismos que podrá emitir en el
                momento en que desee utilizarlos. La generación, resguardo y uso
                de los boletos es responsabilidad exclusiva del usuario. <br />
                Los boletos adquiridos en el mes de enero 2023 serán vigentes
                hasta el 31 de enero 2023, y los boletos adquiridos en el
                período comprendido del 1 de febrero al 31 de diciembre de 2023
                serán vigentes hasta el 31 de diciembre de 2023.
              </li>
              <li>
                La generación de su CFDI podrá realizarla con los datos del
                recibo de pago, al día siguiente de su compra y en un máximo de
                72 horas posteriores al día de compra en la página
                <a href="//www.aafy.yucatan.gob.mx" target="_blank"
                  >www.aafy.yucatan.gob.mx</a
                >.
              </li>
              <li>
                Para cualquier duda o aclaración ponemos a su disposición el
                teléfono (999) 930 30 10 ext. 20127 y 20131, de Lunes a Viernes
                en horario de oficina de 8:00 a 15:00 horas.
              </li>
            </ul>
            <h6 class="text-center my-3 mb-4">
              AVISO DE PRIVACIDAD INTEGRAL <br />
              DIRECCIÓN RECAUDACIÓN <br />
              AGENCIA DE ADMINISTRACIÓN FISCAL DE YUCATÁN
            </h6>
            <p>
              La <strong>Dirección de Recaudación</strong> de la Agencia de
              Administración Fiscal de Yucatán (AAFY) con domicilio en calle 60
              número 299E x 3B y 5B de la Colonia Revolución, CP. 97115, de
              Mérida, Yucatán, es responsable del tratamiento de los datos
              personales que nos proporcione, los cuales serán protegidos
              conforme a lo dispuesto por la Ley General de Protección de Datos
              Personales en Posesión de Sujetos Obligados, Ley de Protección de
              Datos Personales en Posesión de Sujetos Obligados del Estado de
              Yucatán y demás normatividad que resulte aplicable.
            </p>
            <strong
              >¿Qué datos personales solicitamos y para qué fines?
            </strong>
            <p>
              Sus datos personales recabados a través de las solicitudes,
              avisos, declaraciones y demás manifestaciones hechas por medios
              electrónicos o impresos son incorporados, protegidos y tratados en
              nuestros sistemas con la finalidad de llevar a cabo el registro y
              control de los ingresos que se recaudan, para realizar el
              seguimiento y/o determinación del cumplimiento de las obligaciones
              fiscales de los contribuyentes, para la resolución de sus trámites
              o promociones, así como para la elaboración de estadísticas e
              informes de recaudación y la implementación de medidas de control
              interno. De manera adicional utilizaremos su información personal,
              que no es necesaria para el servicio solicitado, pero que nos
              permiten y facilitan brindarle una mejor atención.
            </p>
            <p>
              Para llevar a cabo lo anterior, se recabarán los siguientes datos
              personales: Nombre o razón social, Registro Federal de
              Contribuyentes, Registro Estatal del Contribuyentes, Clave Única
              de Registro de Población, domicilio fiscal, teléfono, correo
              electrónico, firma, generales del solicitante o su representante
              legal, en su caso, o cualquier otro domicilio para oír y recibir
              notificaciones. Se le informa que no se solicitarán datos
              personales sensibles.
            </p>
            <strong
              >¿Con quién compartimos su información personal y para qué
              fines?</strong
            >
            <p>
              Se le comunica que sus datos personales podrán ser transferidos al
              Servicio de Administración Tributaria (SAT) y el Instituto
              Mexicano del Seguro Social (IMSS) con el objeto de vigilar el
              correcto cumplimiento de las obligaciones fiscales de los
              contribuyentes.
            </p>
            <p>
              Así mismo sus datos personales podrán ser transferidos a otras
              Unidades Administrativas que conforman la Agencia de
              Administración Fiscal de Yucatán, a las autoridades judiciales o
              tribunales competentes, a sociedades de información crediticia y
              para efectos de la notificación de terceros; para el ejercicio de
              sus facultades propias, compatibles o análogas.
            </p>
            <p>
              Se informa que no se realizarán transferencias de datos personales
              adicionales, salvo aquellas que sea necesarias para atender
              requerimientos de información de una autoridad competente, que
              estén debidamente fundados y motivados; así como en aquellos casos
              en los que personas físicas o morales hayan sido beneficiadas
              mediantes los programas de estímulos, condonaciones o exenciones
              fiscales.
            </p>
            <strong
              >¿Cuál es el fundamento legal que establece la obligación a la
              Agencia para generar este aviso de privacidad?</strong
            >
            <p>
              Artículo 3, fracción II, 26, 27 y 28 de la
              <u class="text-primary"
                >Ley General de Protección de Datos Personales en Posesión de
                Sujetos Obligados</u
              >, en relación con los diversos 3, fracción II, 25, 26, 27, 28 y
              29 de la
              <u class="text-primary"
                >Ley de Protección de Datos Personales en Posesión de Sujetos
                Obligados del Estado de Yucatán</u
              >.
            </p>
            <strong
              >¿Cuáles son los mecanismos para manifestar su negativa al
              tratamiento de datos personales?
            </strong>
            <p>
              Puede manifestar su negativa a que sus datos personales sean
              tratados para alguna de las finalidades anteriores, desde este
              momento comunicándolo al correo electrónico
              <a href="mailto:solicitudes.aafy@yucatan.gob.mx"
                >solicitudes.aafy@yucatan.gob.mx</a
              >.
            </p>
            <strong>¿Dónde puedo ejercer mis derechos ARCO? </strong>
            <p>
              Usted podrá ejercer sus derechos de acceso, rectificación,
              cancelación u oposición (Derechos ARCO) al tratamiento de sus
              datos personales, de conformidad con el artículo 16 párrafo
              segundo de la Constitución Política de los Estados Unidos
              Mexicanos, así como del título tercero, capítulos I y II de la Ley
              General de Protección de Datos Personales en Posesión de Sujetos
              Obligados, Ley de Protección de Datos Personales en Posesión de
              Sujetos Obligados del Estado de Yucatán. La solicitud del
              ejercicio de sus derechos ARCO podrá realizarla de manera personal
              en la calle 60 número 299E x 3B y 5B colonia Revolución de la
              ciudad de Mérida, mediante el formato de solicitud de derechos
              ARCO de esta Agencia, mismo que podrá descargar en el siguiente
              enlace: <br />
              <a
                href="https://aafy.yucatan.gob.mx/seccion.php?id=15"
                target="_blank"
                >https://aafy.yucatan.gob.mx/seccion.php?id=15</a
              >
            </p>
            <p>
              Pudiendo presentar su solicitud para el ejercicio de los derechos
              de acceso, rectificación, cancelación u oposición de sus datos
              personales (derechos ARCO) directamente ante nuestra Unidad de
              Transparencia, cuyos datos de contacto son los siguientes:
            </p>
            <ul style="list-style-type: lower-latin">
              <li>Nombre de su titular: Lic. José Alonso Guzmán Vega</li>
              <li>
                Domicilio: Calle 60 número 299E, Colonia Revolución, Mérida,
                Mérida, CP. 97115, Yucatán, México
              </li>
              <li>Horario: lunes a viernes de 8 a 15 horas.</li>
              <li>
                Correo electrónico:
                <a href="mailto:solicitudes.aafy@yucatan.gob.mx"
                  >solicitudes.aafy@yucatan.gob.mx</a
                >
              </li>
              <li>Número telefónico y extensión: 930-30-10 extensión 20217</li>
            </ul>
            <p>
              Asimismo, le informamos que usted podrá presentar una solicitud de
              ejercicio de derechos ARCO a través de la Plataforma Nacional de
              Transparencia, disponible en
              <a href="//www.plataformadetransparencia.org.mx" target="_blank"
                >http://www.plataformadetransparencia.org.mx</a
              >.
            </p>
            <strong
              >¿Cómo puede conocer los cambios en este aviso de
              privacidad?</strong
            >
            <p>
              El presente aviso de privacidad puede sufrir modificaciones,
              cambios o actualizaciones derivadas de nuevos requerimientos
              legales o por otras causas, para su consultarlo puede visitar el
              portal
              <a href="//aafy.yucatan.gob.mx/" target="_blank"
                >http://aafy.yucatan.gob.mx/</a
              >
              o en las oficinas de esta Agencia, donde siempre estará a la vista
              la última versión que rige el tratamiento de los datos personales
              proporcionados.
            </p>
            <strong
              >Ultima Fecha de actualización del aviso de privacidad</strong
            >
            <p>09 de febrero de 2021</p>
            <v-row class="mt-5">
              <v-col cols="12" sm="4">
                <p class="text-primary">
                  <small
                    >Calle 60 No. 299-E entre 3-B y 5-B Colonia Revolución, C.P.
                    97115.</small
                  >
                </p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3">
                <p class="text-primary">
                  <small
                    >T +52 (999) 930 3010 <br />
                    <a
                      class="text-primary"
                      href="//www.aafy.yucatan.gob.mx"
                      target="_blank"
                      >www.aafy.yucatan.gob.mx</a
                    ></small
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="my-0 py-0"></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              :disabled="!btnprivacyPolicy"
              :color="'primary'"
              @click="signIn"
              min-width="150"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import https from "https";

export default {
  data() {
    return {
      error: false,
      dialog: false,
      btnprivacyPolicy: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      },
      hidePassword: true,
      loading: false,
      formCoor: {
        id: 0,
        email: "",
        clave: "",
        name: "",
        name2: "",
        name3: "",
        rol: 4,
        subrol: 0,
        sitio: 0,
        avatar: "//gpstuuxan.com/img/user/0.png",
        companyRFC: "",
        companyName: "",
        companyAddress: "",
        companyPhone: "",
      },
    };
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.btnprivacyPolicy = false;
      }
    },
  },
  methods: {
    viewPrivacyPolicy: function () {
      if (this.$refs.signInForm.validate()) {
        this.dialog = true;
        setTimeout(() => {
          document.getElementById("privacyPolicy").scroll({
            top: 0,
            behavior: "smooth",
          });
        }, 10);
      }
    },
    signIn: function () {
      this.dialog = false;
      let agentOptions = {
        rejectUnauthorized: false,
        ca: this.$root.cer,
      };
      const requestOption = {
        method: "POST",
        httpsAgent: new https.Agent(agentOptions),
        url:
          this.$root.myserver +
          this.$root.myport +
          "/wholesaler/CreateWholesaler",
        json: true,
        data: this.formCoor,
      };
      if (this.$refs.signInForm.validate()) {
        axios(requestOption).then((response) => {
          if (response.data[0][0].success == "TRUE") {
            this.$root.swalAlert(
              "success",
              "Registro",
              response.data[0][0].message
            );
            this.clearForm();
            this.changeView();
          } else {
            this.$root.swalAlert(
              "error",
              "Registro",
              response.data[0][0].message
            );
          }
        });
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        this.btnprivacyPolicy = true;
      }
    },
    changeView: function () {
      this.$parent.view = !this.$parent.view;
    },
    clearForm: function () {
      this.formCoor = {
        id: "",
        email: "",
        clave: "",
        name: "",
        name2: "",
        name3: "",
        rol: 4,
        subrol: 0,
        active: 1,
        companyRFC: "",
        companyName: "",
        companyAddress: "",
        companyPhone: "",
      };
      this.hidePassword = true;
      this.btnprivacyPolicy = false;
      this.$refs.signInForm.resetValidation();
    },
    uppercase() {
      this.formCoor.companyRFC = this.formCoor.companyRFC.toUpperCase();
    },
  },
};
</script>

<style scoped>
.MyCardL2 {
  max-width: 550px !important;
  zoom: 85%;
  -moz-transform: scale(0.9);
}
.my_font {
  margin-top: 5px;
  margin-bottom: 10px;
  color: white !important;
}
.rfcInput {
  text-transform: uppercase;
}
/* width */
.terminos::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.terminos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.terminos::-webkit-scrollbar-thumb {
  background: #fa9f31;
  border-radius: 5px;
}

/* Handle on hover */
.terminos::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

.list-number {
  list-style-type: decimal;
}
ul.list-number li::marker {
  font-weight: bold;
}

ul.list-bullet {
  list-style: square;
}
ul.list-bullet li::marker {
  color: red;
  font-size: 18px;
}
.sing-buttons {
  gap: 30px;
}
.withoutpadding {
  padding-bottom: 0 !important;
}

.subtitle {
  text-align: center;
}

@media (max-width: 450px) {
  .MyCardL2 {
    zoom: 90%;
    -moz-transform: scale(1, 0.95);
  }
  .sing-buttons {
    padding: 0px 30px;
    flex-direction: column;
    gap: 3px;
  }
}
</style>